@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Fira Code', monospace !important;
}

.timer{
  width: 100%;
  background-color: black;
  position: sticky;
  color: white;
  top: 0;
  z-index: 10;
  text-align: center;
}